import { axiosInstance } from "../network/interceptors";
import { handleNavigationAfterAuth } from "../utils/shared";
import { createCancelToken } from "../utils/utils";

// import shared from "../store/modules/shared";

const state = {
  token: localStorage.getItem("token") || "",
  user: localStorage.getItem("user") || {},
  step: localStorage.getItem("step") || 1,
  otpVerified: localStorage.getItem("otpVerified") || false,
  email: "",
  calendlyUrl: null,
  bookMeeting: localStorage.getItem("bookMeeting") || false,
  isClientHasNomination: false,
  isClientHasContractRenewal: false,
  isTalentHasContractRenewal: false,
  isClientHasInterviews: false,
  isClientHasNominationData: [],
};
const mutations = {
  auth_success(state, data) {
    state.token = data.access_token;
    state.user = data.user ? data.user : data;
    state.step = data.user?.step ? data.user?.step : data.step;
    state.otpVerified = true;
  },
  auth_fail(state) {
    state.token = "";
    state.user = {};
  },
  set_step(state, step) {
    state.step = step;
  },
  set_bookMeeting(state, val) {
    state.bookMeeting = val;
  },
  set_user(state, user) {
    state.user = user;
  },
  set_email(state, email) {
    state.email = email;
  },
  set_is_client_has_nominations(state, val) {
    state.isClientHasNomination = val;
  },
  set_calendly_url(state, val) {
    state.calendlyUrl = val;
  },
  set_is_client_has_interviews(state, val) {
    state.isClientHasInterviews = val;
  },
  set_is_client_has_contract_renewal(state, val) {
    state.isClientHasContractRenewal = val;
  },
  set_is_talent_has_contract_renewal(state, val) {
    state.isTalentHasContractRenewal = val;
  },
  set_is_client_nominations_data(state, val) {
    state.isClientHasNominationData = val;
  },
};
const actions = {
  oneStepRegister({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/auth/register/one-step-client-wizard", data)
        .then((res) => {
          dispatch("setRegisterEmail", res.data.data.email);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  register({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/auth/register", data)
        .then((res) => {
          dispatch("setRegisterEmail", res.data.data.email);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  otpGenerate({ dispatch }, email) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/auth/generateOTP", { email })
        .then((res) => {
          dispatch("dealWithOTP", email);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  login({ dispatch }, { otpCode, email }) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/auth/login", {
          email,
          otpCode,
          ...(window.location.href.includes("code-challenge")
            ? { in_competition: 1 }
            : null),
        })
        .then((res) => {
          dispatch("dealWithAuthData", res.data.data);
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  async googleAuth({ dispatch }, data) {
    const { auth } = await import("./../firebase/utlis");

    const { signInWithPopup, GoogleAuthProvider } = await import(
      "firebase/auth"
    );
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });

    await signInWithPopup(auth, provider).then((result) => {
      let payload = {
        provider_id: result.user.providerData[0].uid,
        provider: "google",
        access_token: result._tokenResponse.oauthAccessToken,
      };

      payload.marketing_campaign = data.marketing_campaign;

      if (data.type) {
        payload.type = data.type;
      }
      if (data.in_competition) {
        payload.in_competition = 1;
      }
      dispatch("socialAuth", payload);
    });
  },

  socialAuth({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(
          data.type ? "api/auth/social/register" : "api/auth/social/login",
          data
        )
        .then((res) => {
          const { user } = res.data.data;
          dispatch("dealWithAuthData", res.data.data);
          if (data.in_competition) {
            if (user.role) {
              dispatch("shared/handleModalStatus", "success", { root: true });
            } else {
              dispatch("shared/handleModalStatus", "role", { root: true });
            }
          } else {
            handleNavigationAfterAuth(user);
          }
          resolve(user);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  logOut({ dispatch }) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/auth/logout")
        .then(() => {
          dispatch("removeAuthData");
          setTimeout(() => {
            dispatch("setStep", 1);
          }, 20);
          resolve("Success");
        })
        .catch((error) => reject(error))
        .finally(() => {
          // window.location.reload();
        });
    });
  },
  dealWithOTP({ commit }, email) {
    localStorage.setItem("otpVerified", true);
    localStorage.setItem("email", email);
    commit("set_email", email);
  },
  setRegisterEmail({ commit }, email) {
    commit("set_email", email);
  },
  setUser({ commit }, user) {
    commit("set_user", user);
  },
  dealWithAuthData({ commit }, data) {
    localStorage.setItem("token", data.access_token ? data.access_token : null);
    localStorage.setItem(
      "user",
      data.user ? JSON.stringify(data.user) : JSON.stringify(data)
    );
    localStorage.setItem("step", data.user?.step ? data.user.step : data.step);

    if (data.user?.campaign?.id === 15) {
      localStorage.setItem("is_one_step", true);
    }

    commit("set_step", data.user?.step ? data.user.step : data.step);
    commit("auth_success", data);
    commit("set_email", data.email);
  },

  removeAuthData({ commit }) {
    const lang = localStorage.getItem("lang");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("step");
    localStorage.removeItem("first_open_meeting_modal");
    if (localStorage.getItem("zE")) {
      if (window.zE) window.zE("messenger", "hide");
    }

    localStorage.clear();
    localStorage.setItem("lang", lang);
    commit("auth_fail");
  },

  setStep({ commit }, step) {
    if (
      window.location.href.includes("talent") &&
      !window.location.href.includes("talent-profile")
    ) {
      localStorage.setItem("step", step);
      commit("set_step", step);
    } else {
      if (step <= 6) {
        localStorage.setItem("step", step);
        commit("set_step", step);
      } else {
        localStorage.setItem("step", 6);
        commit("set_step", 6);
      }
    }
  },
  setBookMeeting({ commit }, val) {
    localStorage.setItem("BookMeeting", val);
    commit("set_bookMeeting", val);
  },
  postBookMeetingRequest({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/client/projects/bookMeeting", data)
        .then(() => {
          dispatch("setStep", 6);
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  getClientProjectsData({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      const cancelToken = createCancelToken("fetchData");
      axiosInstance
        .get("/api/client/projects", { data, cancelToken })
        .then((res) => {
          let response = res.data.data;
          if (response.meeting_link && response.meeting_start_time) {
            dispatch("setStep", 6);
          }
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },
  getClientNominationState({ commit }, data) {
    let activeProject = JSON.parse(localStorage.getItem("activeProject"));
    if (activeProject) {
      activeProject.id = data ? data : activeProject.id;
      localStorage.setItem("activeProject", JSON.stringify(activeProject));
    }

    return new Promise((resolve, reject) => {
      const cancelToken = createCancelToken("fetchData");
      axiosInstance
        .get(
          data
            ? "/api/talent-nominations/info?project_id=" + data
            : "/api/talent-nominations/info",
          { cancelToken }
        )
        .then((res) => {
          commit(
            "set_is_client_has_nominations",
            res.data.data.nominations_count > 0
          );
          commit("set_is_client_nominations_data", res.data.data);

          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },
  setClientCounters({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/client-counters")
        .then((res) => {
          commit(
            "set_is_client_has_contract_renewal",
            res.data.renewals.renewals_count > 0
          );
          commit(
            "set_is_client_has_nominations",
            res.data.nominations.nominations_count > 0
          );
          commit(
            "set_is_client_has_interviews",
            res.data.meetings.interviews_count > 0
          );
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },
  setClientHasContractRenewal({ commit }) {
    return new Promise((resolve, reject) => {
      /*  const step = JSON.parse(localStorage.getItem("step"));
      if (step < 6) {
        commit("set_is_client_has_contract_renewal", false);
        resolve(false);
        return;
      } */
      axiosInstance
        .get("/api/client/contract-renewal/info")
        .then((res) => {
          commit(
            "set_is_client_has_contract_renewal",
            res.data.data.renewals_count > 0
          );

          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },
  setTalentHasContractRenewal({ commit, getters }) {
    if (!getters.isLoggedIn) {
      commit("set_is_talent_has_contract_renewal", false);
      return;
    }
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/talent/contract-renewal/info`)
        .then((res) => {
          commit(
            "set_is_talent_has_contract_renewal",
            res.data.data.renewals_count > 0
          );
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },
  getCalendlyUrl({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/calendars?meeting_type=${data.meeting_type}&is_default=${data.is_default}`
        )
        .then((res) => {
          resolve(res);
          commit("set_calendly_url", res.data.data[0].link);
        })
        .catch((error) => reject(error));
    });
  },
  setEmail({ commit, getters }, email) {
    let user = getters.getUser;
    user["email"] = email;
    localStorage.setItem("user", JSON.stringify(user));
    commit("set_user", user);
  },

  setIsVerified({ commit, getters }, isVerified) {
    let user = getters.getUser;
    user["isVerified"] = isVerified;
    localStorage.setItem("user", JSON.stringify(user));
    commit("set_user", user);
  },
};
const getters = {
  isLoggedIn: (state) => !!state.token,
  getUserStep: (state) => state.step,
  getUser: (state) => {
    return typeof state.user == "string" ? JSON.parse(state.user) : state.user;
  },
  getEmail: (state) => state.email,
  getCalendlyUrl: (state) => state.calendlyUrl,
  getBookMeeting: (state) => state.bookMeeting,
  getClientNominationState: (state) => state.isClientHasNomination,
  getClientHasContractRenewal: (state) => state.isClientHasContractRenewal,
  getTalentHasContractRenewal: (state) => state.isTalentHasContractRenewal,
  getClientNominationData: (state) => state.isClientHasNominationData,
  getClientHasInterviews: (state) => state.isClientHasInterviews,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
